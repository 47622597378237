<template>
  <Container :title="$t('messages.messages')">
    <progress-bar :loading="loading"/>
    <template v-if="!loading && messages.length">
      <div :class="{ 'scrolling-container': isPhone }">
        <div v-for="message in messages" :key="message.id" class="messages">
          <div
            class="message"
            :class="{ unread: !message.is_done }"
            @click="toggleStatus(message, !message.is_done)"
          >
            <h4>
              <BaseDate :date="message.created_at"/>
            </h4>
            {{ message.message }}
          </div>
        </div>
      </div>
    </template>
    <template v-else> {{ $t('messages.noMessages') }} </template>
  </Container>
</template>

<script>

import Vue from 'vue'

export default Vue.extend({
  name: "Messages",
  data() {
    return {
      messages: [],
      loading: false,
    }
  },
  computed: {
    user: function() {
      return this.$auth.user()
    },
    isPhone: function() {
      return this.$mq === "mobile"
    },
  },
  created() {
    this.getMessages()
  },
  methods: {
    toggleStatus(reminder, is_done) {
      this.axios
        .patch(`/reminders/${reminder.id}/`, { is_done })
        .then(() => {
          this.getMessages()
        })
    },
    getMessages() {
      this.loading = true
      this.axios
        .get(`/reminders/`)
        .then((response) => {
          this.messages = response.data.results
          this.loading = false
        })
        .catch(() => {
          this.loading = false
        })
    },
  },
})
</script>

<style lang="scss">
.messages {
  .message {
    margin-bottom: 20px;
    cursor: pointer;
    &.unread {
      font-weight: bold;
    }
  }
}
</style>
